








































































































































































































































































































































































































































































































































































































































































































.el-textarea__inner {
  min-height: 20rem !important;
  resize: none;
}
/* .itemLabel {
  width: 180px;
  // background: #000;
} */
.itemLabel2 {
  width: 10px;
  // background: #000;
}

.flex {
  display: flex;
}
